export const  data =
[
    {
        user_name:"xxpolina343",
        date:"12-11-24",
        time:"9:26",
        comment:"Просто прочитайте обзор на Apple iPhone 16 Pro, и похоже, что Apple действительно превзошла себя на этот раз! Модернизированная система камеры и улучшения дисплея кажутся идеальными для любого, кто занимается фотографией или играми. Более быстрый процессор также является отличным бонусом для многозадачности",
        photo:""
    },
    {
        user_name:"kirov666",
        date:"11-11-24",
        time:"14:23",
        comment:"В целом, я очень доволен покупкой. IPhone кажется изысканным, тогда как на Android я иногда чувствовал, что использую полувыпеченный продукт. Однако я понимаю, что разработчикам сложно оптимизировать для такого количества различных устройств Android",
        photo:""
    },
    {
        user_name:"антон43",
        date:"10-11-24",
        time:"10:23",
        comment:"Вот и привезли мне наушники",
        photo:"three"
    },
    {
        user_name:"саня питер",
        date:"09-11-24",
        time:"14:43",
        comment:"Так… во-первых, телефон отличный, дисплей огромный. Звук и камера без слов. Поменял 14 Pro Max на новый 16 Pro max. Во-вторых, я ещё не видел таких скидок. И Airpods тоже получил, бесплатно. Как и обещали. Сложно было только найти знакомых которые бы купили себе айфон",
        photo:""
    },
    {
        user_name:"Саша25",
        date:"08-11-24",
        time:"13:09",
        comment:"Здорово. Телефон красивый, камера улет. Минусов пока еще не нашел. Спасибо",
        photo:""
    },
    {
        user_name:"димон_димон",
        date:"07-11-24",
        time:"12:16",
        comment:"Уже получил, спасибо вам",
        photo:""
    },
    {
        user_name:"Лена Павлова",
        date:"06-11-24",
        time:"11:50",
        comment:"Муж подарил новый телефон, но я и незнала что купил со скидкой. Буду наблюдать!",
        photo:""
    },
    {
        user_name:"Андрюха",
        date:"05-11-24",
        time:"11:34",
        comment:"Спасибо",
        photo:""
    },
    {
        user_name:"Олег5587",
        date:"04-11-24",
        time:"11:03",
        comment:"Думал что в этом году нового телефона небуду покупать, но всё равно взял. Аппарат, без слов, удобно лежит в руках и в кармане. Рекомендую!",
        photo:""
    },
    {
        user_name:"Сергей",
        date:"03-11-24",
        time:"10:50",
        comment:"Надо было подаждать, но стоило",
        photo:"one"
    },
    {
        user_name:"Вера",
        date:"02-11-24",
        time:"10:41",
        comment:"Очен профессионально, доставили мне на работе",
        photo:""
    },
    {
        user_name:"Макс",
        date:"01-11-24",
        time:"10:23",
        comment:"Спасибо за скидку",
        photo:""
    },
    {
        user_name:"Лариса",
        date:"31-10-24",
        time:"09:33",
        comment:"Безумно довольна новым Pro Max, спасибо!",
        photo:""
    },
    {
        user_name:"Николай",
        date:"30-10-24",
        time:"09:10",
        comment:"Спасибо",
        photo:""
    },
    {
        user_name:"Людмила",
        date:"29-10-24",
        time:"20:53",
        comment:"сервис доставки супер, аппарат отличный. Спасибо вам!",
        photo:""
    },
    {
        user_name:"Станислав",
        date:"28-10-24",
        time:"20:27",
        comment:"Только что доставили, спасибо за новый телефон",
        photo:""
    },
    {
        user_name:"Гена",
        date:"27-10-24",
        time:"19:55",
        comment:"С начала не хотел верить, но решил купит тоже. Класный телефон!",
        photo:""
    },
    {
        user_name:"Татьяна",
        date:"26-10-24",
        time:"19:36",
        comment:"Спасибо",
        photo:""
    },
    {
        user_name:"Максим",
        date:"25-10-24",
        time:"19:08",
        comment:"Спасибо за низкие цены",
        photo:"two"
    },
    {
        user_name:"Михаил",
        date:"25-10-24",
        time:"18:20",
        comment:"спасибо",
        photo:""
    },
    {
        user_name:"Дмитрий",
        date:"24-10-24",
        time:"17:44",
        comment:"Уже доставили, спасибо",
        photo:""
    },
    {
        user_name:"Юрий С.",
        date:"23-10-24",
        time:"16:52",
        comment:"большое спасибо",
        photo:""
    },
    {
        user_name:"Андрей",
        date:"22-10-24",
        time:"16:10",
        comment:"Безумно спасибо",
        photo:""
    },
    {
        user_name:"Никита",
        date:"21-10-24",
        time:"15:30",
        comment:"спасибо за новый айфон",
        photo:""
    },
    {
        user_name:"Маша",
        date:"20-10-24",
        time:"14:45",
        comment:"спасибо",
        photo:""
    },
    {
        user_name:"Светлана Г.",
        date:"19-10-24",
        time:"13:52",
        comment:"хорошая камера и звук. Как только узнала что есть скидка, сразу же купила",
        photo:""
    },
    {
        user_name:"Толик",
        date:"18-10-24",
        time:"13:24",
        comment:"Купил 16 Про Мах 1тб. Теперь буду фоткать и снимать много видео. Спасибо за скидку. Делюсь з друьями тоже",
        photo:""
    }
];