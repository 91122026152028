import './glavnaya.css';
import React from 'react';
import iphone16 from './pics/main/iPhone16.png'
import ipad from './pics/main/iPad_pro.jpg'
import macbook from './pics/main/macbook_pro.jpg'
import airpods from './pics/main/airpods.jpg'
import watch from './pics/main/apple_watch.jpg'
import Iphone16 from './iphone_page';
import { useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from 'react-router-dom';
import Otzyvy from './otzyvy';
import Otzyvy2 from './otzyvy2';


const Glavnaya = ({kupyt}) => {

    var announce = "Телефоны iPhone 16 со скидкой - Поделись с друзьями и получи наушники AirPods 2 Pro в подарок!"
    const [announceVisible, setAnnounceVisible] = useState(0)

    const showAnnounce = () =>{
        setAnnounceVisible(1)
    }
    const closeAnnounce = () =>{
        setAnnounceVisible(0)
    }

    const AnnounceCard = () =>{
        return(
            <div className='announce_block'>
                <button className='announce_close' onClick={closeAnnounce}>X</button>
                <div className='announce_content'>
                    <h5>Инструкции как получить наушники Airpods 2 Pro!</h5>
                    <p>1. Купите любой из телефонов iPhone 16</p>
                    <p>2. После подтверждения оплаты, вы получите електронное письмо,</p>
                    <p>в котором вы узнаете всю информацию о доставке вашево нового смартфона</p>
                    <p>3. В том самом письме вы найдёте уникальный код, </p>
                    <p> который ваши друзья или знакомые должны использовать при покупке одного из iPhone 16,</p>
                    <p>чтобы тоже взять телефон со скидкой</p>
                    <p>4. Ваши друзья должны отправить этот код к нам по електронной почте при покупке</p>
                    <p>5. Не менее 10 ваших друзей должны купит один из телефонов iPhone 16 </p>
                    <p>6. Если вы нехотите телефон или у вас уже есть, но хотите наушники,</p>
                    <p>не менее 15 ваших друзей должны купить один из iPhone 16 </p>
                </div>
            </div>
        )
    }

    return (
        <div className='glavnaya'>

<button onClick={showAnnounce} className='podelis'>{announce}
</button>
{announceVisible === 0 ? "": <AnnounceCard/>}

            <div className='drugye_tavary'>
                <Link to='/otzyvy'>
                <Otzyvy/>
                </Link>
            </div>
            <div className='apple_produkty'>
                <div className='kupyt_iPhone'>
                    <p className='description'>Телефоны iPhone 16</p>
                    <img className='iphone_img' src={iphone16} alt="iphone16" />
                    <Link to='/iphone16'>

                        <button className="ssylka_iphone">Купить iPhone16</button>
                    </Link>

                </div>
                 <div className='kupyt_macbook'>
                    <p className='description'>Ноутбуки MacBook</p>
                    <img className='macbook_img' src={macbook} alt="ipad" />
                    <Link to='/macbook'>
                    <button className="ssylka_iphone">Купить MacBook</button>
                    </Link>
                </div>
                <div className='kupyt_iPad'>
                    <p className='description'>Планшеты iPad</p>
                    <img className='ipad_img' src={ipad} alt="ipad" />
                    <button className="ssylka_iphone">Скоро будет</button>
                </div>
                <div className='kupyt_airpods'>
                    <p className='description'>Наушники AirPods</p>
                    <img className='airpods_img' src={airpods} alt="airpods" />
                    <button className="ssylka_iphone">Только как подарок</button>
                </div>
               <div className='kupyt_watch'>
                <p className='description'>Часы AppleWatch</p>
                    <img className='watch_img' src={watch} alt="watch" />
                    <button className="ssylka_iphone">Скоро будет</button>
                </div>
                <div className='drugye_tavary'>
                
                
            </div>
            </div>
<div className='footer'>
    <div className='text_block'>
    <p className='footer_text'>
        Бсе правы защищены
    </p>
    <p className='footer_text'>
        Гарантия на каждый товар: 2 года
    </p>
    <p className='footer_text'>
        Гарантия применяется в случае кражи, потери, или других обстоновок, которые не являются результатом неправильного пользования продукта
    </p>
    <p className='footer_text'>
        Доставка товара от 2 до 5 недель. В случае неправильного адреса - до 7 недель
        <p className='footer_text'>Все продукты на этом сайте являются новыми, в оригинaльной упаковке</p>
    </p>
    </div>
</div>

        </div>
    );
}

export default Glavnaya;