import './iphone16.css';
import React from 'react';
import { useState } from 'react';
import Promax from './components/Iphone16/promax';
import Pro from './components/Iphone16/pro';
import Plus from './components/Iphone16/plus';
import Iphone from './components/Iphone16/iphone';

const Iphone16 = (props) => {

    const [phone, setPhone] = useState(0)

    const setPM = () => {
        setPhone(0)
    }
    const setP = () => {
        setPhone(1)
    }
    const setPl = () => {
        setPhone(2)
    }
    const setSimple = () => {
        setPhone(3)
    }

    const [cart, setCart] = useState(0)

    var optn = ['iPhone 16 Pro Max', 'iPhone 16 Pro', 'iPhone 16 Plus', 'iPhone 16']
    var card = [<Promax value={props.value} handleChange={props.handleChange}/>, <Pro value={cart}/>, <Plus value={cart}/>, <Iphone value={cart}/>]

    const PageZero = () => {
        return (
            <span className='page_left'>
                <button onClick={setP} className='right_active' >{optn[1] + " >"}</button>
            </span>
        );
    }

    const PageOne = () => {
        return (
            <span className='page_left'>
                <button onClick={setPM} className='right_active' >{"< " + optn[0]}</button>
                <button onClick={setPl} className='right_active' >{optn[2] + " >"}</button>
            </span>
        );
    }

    const PageTwo = () => {
        return (
            <span className='page_left'>
                <button onClick={setP} className='right_active' >{"< " + optn[1]}</button>
                <button onClick={setSimple} className='right_active' >{optn[3] + " >"}</button>
            </span>
        );
    }
    const PageThree = () => {
        return (
            <span className='page_left'>
                <button onClick={setPl} className='right_active' >{"< " + optn[2]}</button>
            </span>
        );
    }

    

    

    return (

        <div>
            

            {/* navigation */}
            <div className='main_parent'>
                <div className='navi'>
                    {phone == 0 ? <PageZero /> : ""}
                    {phone == 1 ? <PageOne /> : ""}
                    {phone == 2 ? <PageTwo /> : ""}
                    {phone == 3 ? <PageThree /> : ""}


                </div>

                {/*content body*/}
                {card[phone]}

            </div>
        </div>

    );
}
export default Iphone16;