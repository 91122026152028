import React from "react";
import black from '../../pics/plus/black.jpg';
import black_back from '../../pics/plus/black_back.jpg';
import black_bottom from '../../pics/plus/black_bottom.jpg';
import black_top from '../../pics/plus/black_top.jpg';
import ultramarine from '../../pics/plus/ultramarine.jpg';
import ultramarine_back from '../../pics/plus/ultramarine_back.jpg';
import ultramarine_bottom from '../../pics/plus/ultramarine_bottom.jpg';
import ultramarine_top from '../../pics/plus/ultramarine_top.jpg';
import teal from '../../pics/plus/teal.jpg';
import teal_back from '../../pics/plus/teal_back.jpg';
import teal_bottom from '../../pics/plus/teal_bottom.jpg';
import teal_top from '../../pics/plus/teal_top.jpg';
import pink from '../../pics/plus/pink.jpg';
import pink_back from '../../pics/plus/pink_back.jpg';
import pink_bottom from '../../pics/plus/pink_bottom.jpg';
import pink_top from '../../pics/plus/pink_top.jpg';
import white from '../../pics/plus/white.jpg';
import white_back from '../../pics/plus/white_back.jpg';
import white_bottom from '../../pics/plus/white_bottom.jpg';
import white_top from '../../pics/plus/white_top.jpg';
import '../../App.css';
import { useState } from 'react';

function Plus() {

  //product codes
  const proMax = {
    small:  "0NIC7LohYxbDmSluYDqv3YkAriaP",
    medium:  "79vJQwdVpFVPIPcIiXrQlyWhxdr3",
    large:  "ZBbAHv01mjwfXUHhCcIM814W3RPW"
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  
  const [option, setOption] = useState(0)  //colors option setting. Non modal view
  const [cena, setCena] = useState(100000)  //original price setting. Both modal and non modal view
  const [storage, setStorage] = useState("128 GB")  //storage option string. Both modal and non modal view
  const [action, setAction] = useState(0) //reference to different photographic views of a device. Non modal view
  const [diz1ount, setDixchount] = useState(0); //discounted price option. Both modal and non modal view
  const [colorName, setColorName] = useState("Черный"); //string name of device color. Modal viewv
  const [modalVisible, setModalVisible] = useState(0); //setting to view or close the modal
  const [productCode, setProductCode] = useState(proMax.small) //product code setting used to include in an email

   //var address = "bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0"
//---------------------------------------------------------------------------------------------------------------------------------------------------
const closeModal = () => {
  setModalVisible(0);
};

const toKart = () => {
  setModalVisible(1);
};
//---------------------------------------------------------------------------------------------------------------------------------------------------
const Mailto = ({ email, subject = "", body = "", children }) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return (
    <a className="email_link" href={`mailto:${email}${params}`}>
      {children}
    </a>
  );
};
//---------------------------------------------------------------------------------------------------------------------------------------------------
const makeSubject = "Покупаю телефон iPhone 16 Plus " + storage;
const makeBody =
"Телефон iPhone 16 Plus " + storage + "\n\n Цвет: " + colorName +
  "\n\nКод продукта:----- " +
  productCode + option +
  "------\n\n\n Скриншот, как подтверждение об оплате (добавте скриншот),\n или ваш биткойн адрес: \n\n Имя:   \n\n Фамилия: \n\n Полный адрес для доставки: \n\n";
//---------------------------------------------------------------------------------------------------------------------------------------------------

const Modal = () => {
  return (
    <>
      <div className={modalVisible == 0 ? "darkBG_hidden" : "darkBG_visible"}>
        <div className="centered">
          <div className="modal">
            <div className="modalHeader">
              <p className="heading">Карзина</p>
            </div>
            <button onClick={closeModal} className="zakryt">
              Закрыть
            </button>
            <div className="modalContent">
              {/* content load here */}
              <div className="product">
                <p className="karzyna_medium_desc">
                  Телефон iPhone 16 Plus {storage}
                </p>
                <br />
                <p className="karzyna_medium_desc">{colorName}</p>

                <img
                  className="karzyna_pic"
                  src={color_img[option]}
                  alt="телефон"
                />

                <p className="medium_desc">{cena} руб.</p>
                
                <p className="medium_desc">Доставка 1000 руб.</p>
                <p className="vsevo">Всево {cena + 1000} руб.</p>
              </div>
              <div className="info">
                <div className="vnimanye">
                  <p>Внимание!</p>
                  <p>Оплата карточкой неработает!</p>
                </div>
                <p className="medium_dec">
                  Для безопасности вашей информации
                </p>
                <p>оплата за товар применяется криптовалютой</p>
                <p className="vsevo">Информация об оплате!</p>
                <p className="medium_desc">Адрес биткойн:</p>
                <p className="p_address">
                  bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0
                </p>
                <div style={{ borderBottom: "2px solid black" }}></div>
                <p className="medium_desc">
                  Канал отправления биткойн: BTC (Bitcoin){" "}
                </p>
                <br />
                <p className="kupyt_info">
                  Необходимо сделать скриншот с вами использовонной платформы
                  криптовалюты как подтверждение оплаты,
                </p>
                <p className="kupyt_info">
                   или во второй способ, отправте ваш
                  биткойн адрес
                </p>
                <p className="kupyt_info">
                  Нажмите по ссылке внизу, чтобы отправить e-mail
                </p>
                <p className="kupyt_info">
                  в котором введёте вашу информацию для доставки товара
                </p>
                <p className="kupyt_info">
                  Вся информация о покупке по этой ссылке зашифрована
                </p>
                <br />
                <Mailto
                  email={"info@maonline.shop"}
                  subject={makeSubject}
                  body={makeBody}
                >
                  Отправить e-mail!
                </Mailto>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
//---------------------------------------------------------------------------------------------------------------------------------------------------

  const setBlack = () => {
    setAction(0)
    setOption(0)
    setColorName("Черный");
  }

  const setUltramarine = () => {
    setAction(0)
    setOption(1)
    setColorName("Ультрамарин");
  }

  const setTeal = () => {
    setAction(0)
    setOption(2)
    setColorName("Зелёный");
  }

  const setPink = () => {
    setAction(0)
    setOption(3)
    setColorName("Розавый");
  }
  const setWhite = () => {
    setAction(0)
    setOption(4)
    setColorName("Белый");
  }
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const setSmall = () => {
    setDixchount(0)
    setCena(100000)
    setStorage("128 GB")
    setProductCode(proMax.small)
  }

  const setMedium = () => {
    setDixchount(0)
    setCena(115000)
    setStorage("256 GB")
    setProductCode(proMax.medium)
  }

  const setLarge = () => {
    setDixchount(0)
    setCena(140000)
    setStorage("512 GB")
    setProductCode(proMax.large)
  }
//---------------------------------------------------------------------------------------------------------------------------------------------------
 
  const prev = () => {
    setAction(action - 1)
  }
  const next = () => {
    setAction(action + 1)
  }

  const ShowBlack = () => {

    return (
      <div className="slideshow_container">
        <img src={first[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 2 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 3 ? <a className="prev" onClick={prev}>❮</a> : ''}
      </div>
    );


  }
  const ShowUltramarine = () => {

    return (
      <div className="slideshow_container">
        <img src={second[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 2 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 3 ? <a className="prev" onClick={prev}>❮</a> : ''}
      </div>
    );


  }
  const ShowTeal = () => {

    return (
      <div className="slideshow_container">
        <img src={third[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 2 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 3 ? <a className="prev" onClick={prev}>❮</a> : ''}
      </div>
    );


  }
  const ShowPink = () => {

    return (
      <div className="slideshow_container">
        <img src={fourth[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 2 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 3 ? <a className="prev" onClick={prev}>❮</a> : ''}
      </div>
    );
  }
  const ShowWhite = () => {

    return (
      <div className="slideshow_container">
        <img src={fifth[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 2 ? <span><a className="prev" onClick={prev}>❮</a> <a className="next" onClick={next}>❯</a></span>: ''}
        {action == 3 ? <a className="prev" onClick={prev}>❮</a> : ''}
      </div>
    );


  }
//---------------------------------------------------------------------------------------------------------------------------------------------------
 
  var first = [black, black_back, black_bottom, black_top]
  var second = [ultramarine, ultramarine_back, ultramarine_bottom, ultramarine_top]
  var third = [teal, teal_back, teal_bottom, teal_top]
  var fourth = [pink, pink_back,pink_bottom, pink_top] 
  var fifth = [white, white_back,white_bottom, white_top]
  var color_img = [black, ultramarine, teal, pink, white];
//---------------------------------------------------------------------------------------------------------------------------------------------------
 
    return (
        <div>
            <div className='main'>
        <div className='phone_name'>
        <p className="prodano_mobile">*** Продано 24 шт. ***</p>
          <p className='large_desc'>iPhone 16 Plus</p>
        </div>
        <div className='phone_cost'>
          <p className='medium_desc'>,-{cena} руб.</p>
        </div>
        <div className='photo'>
          
        {option == 0 ? <ShowBlack /> : ''}
          {option == 1 ? <ShowUltramarine /> : ''}
          {option == 2 ? <ShowTeal /> : ''}
          {option == 3 ? <ShowPink /> : ''}
          {option == 4 ? <ShowWhite /> : ''}

        </div>
        <div className='colors'>
          <p className='cvet'>Цвет:</p>
          <button onClick={setBlack} className={option == 0 ? 'colors_button_plus_active_black' : 'colors_button_plus_black'}></button>
          <button onClick={setUltramarine} className={option == 1 ? 'colors_button_plus_active_ultramarine' : 'colors_button_plus_ultramarine'}></button>
          <button onClick={setTeal} className={option == 2 ? 'colors_button_plus_active_teal' : 'colors_button_plus_teal'}></button>
          <button onClick={setPink} className={option == 3 ? 'colors_button_plus_active_pink' : 'colors_button_plus_pink'}></button>
          <button onClick={setWhite} className={option == 4 ? 'colors_button_plus_active_white' : 'colors_button_plus_white'}></button>
        </div>
        <div className='phone_info'>
        <p className="medium_desc">Выбор цвета:</p>
        <p className="small_desc">Черный, Ультрамарин, Зелёный, Розовый, Белый </p>
        <br/>
          <p className='small_desc'>Размеры: 160 - 78 - 8 мм</p>
          <p className='small_desc'>Вес: 199 грам</p>
          <p className='small_desc'>Диаметр экрана: 6.7 дюймов</p><br/>
        </div>
        <div className='storage'>
          <p className='xranilische'>Память:</p>
          <button onClick={setSmall} className={cena == 100000 ? 'storage_selector_active' : 'storage_selector'}>128 гб</button>
          <button onClick={setMedium} className={cena == 115000 ? 'storage_selector_active' : 'storage_selector'}>256 гб</button>
          <button onClick={setLarge} className={cena == 140000 ? 'storage_selector_active' : 'storage_selector'}>512 гб</button>
        </div>
        <div className='kupyt'>
          <button onClick={toKart} className='knopka_kupyt'>Взять за {cena} руб.</button>
          <p className='prodano'>*** Продано 24 шт. ***</p>
          <Modal />
        </div>

      </div>
      <button onClick={toKart} className='kupyt_mobile'>Взять за {cena} руб.</button>
      <Modal />
        </div>

    );

}
export default Plus;