import React from "react";
import '../../macbook.css';
import black from '../../pics/airM2/airM2black.jpg'
import black_top from '../../pics/airM2/airM2blacktop.jpg'
import grey from '../../pics/airM2/airM2grey.jpg'
import grey_top from '../../pics/airM2/airM2greytop.jpg'
import silver from '../../pics/airM2/airM2silver.jpg'
import silver_top from '../../pics/airM2/airM2silvertop.jpg'
import starlight from '../../pics/airM2/airM2starlight.jpg'
import starlight_top from '../../pics/airM2/airM2starlighttop.jpg'
import { useState } from 'react';

const AirM2 = () => {

    //product codes
  const proMax = {
    small:  "S7OPrlbgY7tSrf65Db7t2L7XCGDa",
    medium:  "NPltFTksaTOMq2udpRHsEqO12YKz",
    large:  "UJJK676VFlYs2xU6oVa8JCkbv92h"
  };

    const [option, setOption] = useState(0)
    const [power, setpower] = useState("30W")
    const [cena, setCena] = useState(97000)
    const [action, setAction] = useState(0)
    const [diz1ount, setDixchount] = useState(0); //discounted price option. Both modal and non modal view
    const [colorName, setColorName] = useState("Черный"); //string name of device color. Modal viewv
    const [modalVisible, setModalVisible] = useState(0); //setting to view or close the modal
    const [productCode, setProductCode] = useState(proMax.small) //product code setting used to include in an email

   //var address = "bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0"
//---------------------------------------------------------------------------------------------------------------------------------------------------
const closeModal = () => {
    setModalVisible(0);
  };
  
  const toKart = () => {
    setModalVisible(1);
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
  
    return (
      <a className="email_link" href={`mailto:${email}${params}`}>
        {children}
      </a>
    );
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const makeSubject = "Покупаю ноутбук MacBook Air 13' M2 " + power;
  const makeBody =
  "Ноутбук MacBook Air 13' M2 " + power + "\n\n Цвет: " + colorName +
    "\n\nКод продукта:----- " +
    productCode + option +
    "------\n\n\n Скриншот, как подтверждение об оплате (добавте скриншот),\n или ваш биткойн адрес: \n\n Имя:   \n\n Фамилия: \n\n Полный адрес для доставки: \n\n";
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const Modal = () => {
    return (
      <>
        <div className={modalVisible == 0 ? "darkBG_hidden" : "darkBG_visible"}>
          <div className="centered">
            <div className="modal">
              <div className="modalHeader">
                <p className="heading">Карзина</p>
              </div>
              <button onClick={closeModal} className="zakryt">
                Закрыть
              </button>
              <div className="modalContent">
                {/* content load here */}
                <div className="product">
                  <p className="karzyna_medium_desc">
                  Ноутбук MacBook Air 13' M2  {power}
                  </p>
                  <br />
                  <p className="karzyna_medium_desc">{colorName}</p>
  
                  <img
                    className="karzyna_pic"
                    src={color_img[option]}
                    alt="macbook"
                  />
  
                  <p className="medium_desc">{cena} руб.</p>
                  
                  <p className="medium_desc">Доставка 1000 руб.</p>
                  <p className="vsevo">Всево {cena + 1000} руб.</p>
                </div>
                <div className="info">
                  <div className="vnimanye">
                    <p>Внимание!</p>
                    <p>Оплата карточкой неработает!</p>
                  </div>
                  <p className="medium_dec">
                    Для безопасности вашей информации
                  </p>
                  <p>оплата за товар применяется криптовалютой</p>
                  <p className="vsevo">Информация об оплате!</p>
                  <p className="medium_desc">Адрес биткойн:</p>
                  <p className="p_address">
                    bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0
                  </p>
                  <div style={{ borderBottom: "2px solid black" }}></div>
                  <p className="medium_desc">
                    Канал отправления биткойн: BTC (Bitcoin){" "}
                  </p>
                  <br />
                  <p className="kupyt_info">
                    Необходимо сделать скриншот с вами использовонной платформы
                    криптовалюты как подтверждение оплаты,
                  </p>
                  <p className="kupyt_info">
                     или во второй способ, отправте ваш
                    биткойн адрес
                  </p>
                  <p className="kupyt_info">
                    Нажмите по ссылке внизу, чтобы отправить e-mail
                  </p>
                  <p className="kupyt_info">
                    в котором введёте вашу информацию для доставки товара
                  </p>
                  <p className="kupyt_info">
                    Вся информация о покупке по этой ссылке зашифрована
                  </p>
                  <br />
                  <Mailto
                    email={"info@maonline.shop"}
                    subject={makeSubject}
                    body={makeBody}
                  >
                    Отправить e-mail!
                  </Mailto>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  
    const setBlack = () => {
        setAction(0)
        setOption(0)
        setColorName("Черный");
    }

    const setGrey = () => {
        setAction(0)
        setOption(1)
        setColorName("Серый");
    }

    const setSilver = () => {
        setAction(0)
        setOption(2)
        setColorName("Серебряный");
    }

    const setStarlight = () => {
        setAction(0)
        setOption(3)
        setColorName("Старлайт");
    }

    const setSmall = () => {
        setDixchount(0)
        setCena(97000)
        setpower("30W")
        setProductCode(proMax.small)
    }
    const setMedium = () => {
        setDixchount(1)
        setCena(98500)
        setpower("35W")
        setProductCode(proMax.medium)
    }
    const setLarge = () => {
        setDixchount(2)
        setCena(100000)
        setpower("70W")
        setProductCode(proMax.large)
    }
//---------------------------------------------------------------------------------------------------------------------------------------------------
 
    const prev = () => {
        setAction(0)
    }
    const next = () => {
        setAction(1)
    }

    const ShowBlack = () => {

        

        return (
            <div className="slideshow_container ">
                <span className="placeholder gone"></span>
                <img src={first[action]} alt='pmblack' className="pic_res" />
                {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
                {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

            </div>
        );


    }
    const ShowGrey = () => {
        
        return (
            <div className="slideshow_container ">
                <img src={second[action]} alt='pmblack' className="pic_res" />
                {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
                {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

            </div>
        );


    }
    const ShowSilver = () => {
        
        return (
            <div className="slideshow_container ">
                <img src={third[action]} alt='pmblack' className="pic_res" />
                {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
                {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

            </div>
        );


    }
    const ShowStarlight = () => {
       
        return (
            <div className="slideshow_container ">
                <img src={fourth[action]} alt='pmblack' className="pic_res" />
                {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
                {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

            </div>
        );


    }

    var first = [black, black_top]
    var second = [grey, grey_top]
    var third = [silver, silver_top]
    var fourth = [starlight, starlight_top]
    var color_img = [black, grey, silver, starlight];  

    return (
        <div>
            <div className='macbook_main'>
                <div className='macbook_name'>
                <p className="prodano_mobile">*** Продано 11 шт. ***</p>
                    <p className='large_desc'>Ноутбук MacBook Air 13" M2</p>
                    <p className='medium_desc'>Чип Apple M2, 8-ядерный процессор, </p>
                    <p className="medium_desc">8-ядерный графический процессор,</p>
                    <p className='medium_desc'>16-ядерный Neural Engine </p>
                </div>
                <div className='macbook_cost'>
                    <p className='macbook_title_cost'>,-{cena} руб.</p>
                </div>
                <div className='macbook_photo'>

                    {option == 0 ? <ShowBlack /> : ''}
                    {option == 1 ? <ShowGrey /> : ''}
                    {option == 2 ? <ShowSilver /> : ''}
                    {option == 3 ? <ShowStarlight /> : ''}


                </div>
                <div className='macbook_colors'>
                    <p className='cvet'>Цвет:</p>
                    <button onClick={setBlack} className={option == 0 ? 'colors_button_active_black' : 'colors_button_black'}></button>
                    <button onClick={setGrey} className={option == 1 ? 'colors_button_active_grey' : 'colors_button_grey'}></button>
                    <button onClick={setSilver} className={option == 2 ? 'colors_button_active_silver' : 'colors_button_silver'}></button>
                    <button onClick={setStarlight} className={option == 3 ? 'colors_button_active_starlight' : 'colors_button_starlight'}></button>
                </div>
                <div className='macbook_info'>
                    <p className='small_desc'>Диаметр экрана: 13,6"</p>
                    <p className='small_desc'>8ГБ RAM</p>
                    <p className='small_desc'>SSD-накопитель: 256ГБ</p>
                    <p className="small_desc">Адаптер питания USB-C 30W, 35W или 70W</p>
                    <p className="small_desc">Подсветка клавиатуры</p>
                </div>
                <div className='power'>
                    <p className='power_text'>Адаптер питания:</p>
                    <button onClick={setSmall} className={cena == 97000 ? 'power_selector_active' : 'power_selector'}>30W</button>
                    <button onClick={setMedium} className={cena == 98500 ? 'power_selector_active' : 'power_selector'}>35W</button>
                    <button onClick={setLarge} className={cena == 100000 ? 'power_selector_active' : 'power_selector'}>70W</button>
                </div>
                <div className='macbook_kupyt'>
                    <button onClick={toKart} className='knopka_kupyt'>Взять за {cena} руб.</button>
                    <p className='prodano'>*** Продано 11 шт. ***</p>
                    <Modal />
                </div>

            </div>
            <button onClick={toKart} className='kupyt_mobile'>Взять за {cena} руб.</button>
            <Modal />
        </div>
    );
}

export default AirM2;