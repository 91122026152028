import React from "react";
import '../../macbook.css';
import black from '../../pics/airM2/airM2black.jpg'
import black_top from '../../pics/airM2/airM2blacktop.jpg'
import grey from '../../pics/airM2/airM2grey.jpg'
import grey_top from '../../pics/airM2/airM2greytop.jpg'
import silver from '../../pics/airM2/airM2silver.jpg'
import silver_top from '../../pics/airM2/airM2silvertop.jpg'
import starlight from '../../pics/airM2/airM2starlight.jpg'
import starlight_top from '../../pics/airM2/airM2starlighttop.jpg'
import { useState } from 'react';

const AirM2Plus = () => {

  //product codes
const productCodes = {
  m8gb_256gb : "su4yBS6yeJ7NBX608w0EfvnrNovy",
  m8gb_512gb : "bqz2zHG6QURtQCOuQIpYR5oPk4yp",
  m8gb_1tb : "WZYYBf3TJ8M9xOWBvmdC6h4gEriP",
  m8gb_2tb : "BVDW5ThbSWokYPWcFfjuzcQlIYRn",
  m16gb_256gb : "pHeWbJ7ooSrLHQXlmDHxt5r6BNJ3",
  m16gb_512gb : "tnIcKAsinalfdPdrFeET1iavOya7",
  m16gb_1tb : "IYyhRbx0xMcgZTuptbmBX3Y21PeO",
  m16gb_2tb : "2YaFOvMx9p05VCpJ4mrEI6lm1ull",
  m24gb_256gb : "0DMpDHNtNukZRLxdMa8FKt6bcg7C",
  m24gb_512gb : "sY5fyIrR6xI6M0FDsV5UARsvrQeR",
  m24gb_1tb : "LsElbEiYrQIbmgAJBFwHTpnf8KkQ",
  m24gb_2tb : "YUKa4Crz4eyfXEsLqiT27aXLYSxc",
}


  const [option, setOption] = useState(0) //color option
  const [power, setpower] = useState("35W")
  const [ssd, setSsd] = useState("256 GB")
  const [ram, setRam] = useState("8 GB")
  const [total, setTotal] = useState(95000) //cena
  const [action, setAction] = useState(0)
  const [diz1ount, setDixchount] = useState(0); //discounted price option. Both modal and non modal view
    const [colorName, setColorName] = useState("Черный"); //string name of device color. Modal viewv
    const [modalVisible, setModalVisible] = useState(0); //setting to view or close the modal
  const [productCode, setProductCode] = useState(productCodes.m8gb_256gb)
  var ssd_value = 0
  var ram_value = 0


//function to apply a product code to send via email
 const applyProductCode = (ssd_option, ram_option) =>{

  if(ram_option == "8 GB" && ssd_option == "256 GB"){
    setProductCode(productCodes.m8gb_256gb)
    setTotal(95000)
  }
  else if(ram_option == "8 GB" && ssd_option == "512 GB"){
    setProductCode(productCodes.m8gb_512gb)
    setTotal(113000)
  }
  else if(ram_option == "8 GB" && ssd_option == "1 TB"){
    setProductCode(productCodes.m8gb_1tb)
    setTotal(149000)
  }
  else if(ram_option == "8 GB" && ssd_option == "2 TB"){
    setProductCode(productCodes.m8gb_2tb)
    setTotal(170000)
  }
  else if(ram_option == "16 GB" && ssd_option == "256 GB"){
    setProductCode(productCodes.m16gb_256gb)
    setTotal(1150000)
  }
  else if(ram_option == "16 GB" && ssd_option == "512 GB"){
    setProductCode(productCodes.m16gb_512gb)
    setTotal(133000)
  }
  else if(ram_option == "16 GB" && ssd_option == "1 TB"){
    setProductCode(productCodes.m16gb_1tb)
    setTotal(169000)
  }
  else if(ram_option == "16 GB" && ssd_option == "2 TB"){
    setProductCode(productCodes.m16gb_2tb)
    setTotal(190000)
  }
  else if(ram_option == "24 GB" && ssd_option == "256 GB"){
    setProductCode(productCodes.m24gb_256gb)
    setTotal(135000)
  }
  else if(ram_option == "24 GB" && ssd_option == "512 GB"){
    setProductCode(productCodes.m24gb_512gb)
    setTotal(153000)
  }
  else if(ram_option == "24 GB" && ssd_option == "1 TB"){
    setProductCode(productCodes.m24gb_2tb)
    setTotal(189000)
  }
  else if(ram_option == "24 GB" && ssd_option == "2 TB"){
    setProductCode(productCodes.m24gb_2tb)
    setTotal(210000)
  }

 }
//var address = "bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0"
//---------------------------------------------------------------------------------------------------------------------------------------------------
const closeModal = () => {
  setModalVisible(0);
};

const toKart = () => {
  setModalVisible(1);
};
//---------------------------------------------------------------------------------------------------------------------------------------------------
const Mailto = ({ email, subject = "", body = "", children }) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return (
    <a className="email_link" href={`mailto:${email}${params}`}>
      {children}
    </a>
  );
};
//---------------------------------------------------------------------------------------------------------------------------------------------------
const makeSubject = "Покупаю ноутбук MacBook Air 13' M2 10-core GPU" + power;
  const makeBody =
  "Ноутбук MacBook Air 13' M2 10-core GPU" + power + "\n\n Цвет: " + colorName +
    "\n\nКод продукта:----- " +
    productCode + option +
    "------\n\n\n Скриншот, как подтверждение об оплате (добавте скриншот),\n или ваш биткойн адрес: \n\n Имя:   \n\n Фамилия: \n\n Полный адрес для доставки: \n\n";
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const Modal = () => {
    return (
      <>
        <div className={modalVisible == 0 ? "darkBG_hidden" : "darkBG_visible"}>
          <div className="centered">
            <div className="modal">
              <div className="modalHeader">
                <p className="heading">Карзина</p>
              </div>
              <button onClick={closeModal} className="zakryt">
                Закрыть
              </button>
              <div className="modalContent">
                {/* content load here */}
                <div className="product">
                  <p className="karzyna_medium_desc">
                  Ноутбук MacBook Air 13' M2 10-core GPU  {power}
                  </p>
                  <br />
                  <p className="karzyna_medium_desc">{colorName}</p>
  
                  <img
                    className="karzyna_pic"
                    src={color_img[option]}
                    alt="macbook"
                  />
  
                  <p className="medium_desc">{total} руб.</p>
                  
                  <p className="medium_desc">Доставка 1000 руб.</p>
                  <p className="vsevo">Всево {total + 1000} руб.</p>
                </div>
                <div className="info">
                  <div className="vnimanye">
                    <p>Внимание!</p>
                    <p>Оплата карточкой неработает!</p>
                  </div>
                  <p className="medium_dec">
                    Для безопасности вашей информации
                  </p>
                  <p>оплата за товар применяется криптовалютой</p>
                  <p className="vsevo">Информация об оплате!</p>
                  <p className="medium_desc">Адрес биткойн:</p>
                  <p className="p_address">
                    bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0
                  </p>
                  <div style={{ borderBottom: "2px solid black" }}></div>
                  <p className="medium_desc">
                    Канал отправления биткойн: BTC (Bitcoin){" "}
                  </p>
                  <br />
                  <p className="kupyt_info">
                    Необходимо сделать скриншот с вами использовонной платформы
                    криптовалюты как подтверждение оплаты,
                  </p>
                  <p className="kupyt_info">
                     или во второй способ, отправте ваш
                    биткойн адрес
                  </p>
                  <p className="kupyt_info">
                    Нажмите по ссылке внизу, чтобы отправить e-mail
                  </p>
                  <p className="kupyt_info">
                    в котором введёте вашу информацию для доставки товара
                  </p>
                  <p className="kupyt_info">
                    Вся информация о покупке по этой ссылке зашифрована
                  </p>
                  <br />
                  <Mailto
                    email={"info@maonline.shop"}
                    subject={makeSubject}
                    body={makeBody}
                  >
                    Отправить e-mail!
                  </Mailto>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  
  const setBlack = () => {
    setAction(0)
    setOption(0)
    setColorName("Черный");
  }

  const setGrey = () => {
    setAction(0)
    setOption(1)
    setColorName("Серый");
  }

  const setSilver = () => {
    setAction(0)
    setOption(2)
    setColorName("Серебряный");
  }

  const setStarlight = () => {
    setAction(0)
    setOption(3)
    setColorName("Старлайт");
  }

  
  const setMedium = () => {
    setpower("35W")
    applyProductCode(ssd, ram)
    
  }
  const setLarge = () => {
    setpower("70W")
    applyProductCode(ssd, ram)
   
  }
  const setSmallSsd = () => {
    setSsd("256 GB")
  
    if(ram == "8 GB"){
      setTotal(95000)
    }
    else if(ram == "16 GB"){
      setTotal(115000)
    }
    else if(ram == "24 GB"){
      setTotal(135000)
    }
   
  }
  const setMediumSSd = () => {
    setSsd("512 GB")
    //18000 54000 75000
    if(ram == "8 GB"){
      setTotal(113000)
    }
    else if(ram == "16 GB"){
      setTotal(133000)
    }
    else if(ram == "24 GB"){
      setTotal(153000)
    }
  }
  const setLargeSSD = () => {
    setSsd("1 TB")
   
    if(ram == "8 GB"){
      setTotal(149000)
    }
    else if(ram == "16 GB"){
      setTotal(169000)
    }
    else if(ram == "24 GB"){
      setTotal(189000)
    }
    
  }
  const setXLargeSsd = () => {
    setSsd("2 TB")
   
    if(ram == "8 GB"){
      setTotal(170000)
    }
    else if(ram == "16 GB"){
      setTotal(190000)
    }
    else if(ram == "24 GB"){
      setTotal(210000)
    }
    
  }
  const setSmallRam = () => {
    setRam("8 GB")

    if(ssd == "256 GB"){
      setTotal(95000)
    }
    else if(ssd == "512 GB"){
      setTotal(113000)
    }
    else if(ssd == "1 TB"){
      setTotal(1490000)
    }
    else if(ssd == "2 TB"){
      setTotal(170000)
    }
    
  }
  const setMediumRam = () => {
    setRam("16 GB")
    
    if(ssd == "256 GB"){
      setTotal(115000)
    }
    else if(ssd == "512 GB"){
      setTotal(133000)
    }
    else if(ssd == "1 TB"){
      setTotal(1690000)
    }
    else if(ssd == "2 TB"){
      setTotal(190000)
    }
    
  }
  const setLargeRam = () => {
    setRam("24 GB")
   
    if(ssd == "256 GB"){
      setTotal(135000)
    }
    else if(ssd == "512 GB"){
      setTotal(153000)
    }
    else if(ssd == "1 TB"){
      setTotal(189000)
    }
    else if(ssd == "2 TB"){
      setTotal(210000)
    }
  }

  const prev = () => {
    setAction(0)
  }
  const next = () => {
    setAction(1)
  }


  const ShowBlack = () => {

    return (
      <div className="slideshow_container">
        <img src={first[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

      </div>
    );


  }
  const ShowGrey = () => {

    return (
      <div className="slideshow_container">
        <img src={second[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

      </div>
    );


  }
  const ShowSilver = () => {

    return (
      <div className="slideshow_container">
        <img src={third[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

      </div>
    );


  }
  const ShowStarlight = () => {

    return (
      <div className="slideshow_container">
        <img src={fourth[action]} alt='pmblack' className="pic_res" />
        {action == 0 ? <a className="next" onClick={next}>❯</a> : ''}
        {action == 1 ? <a className="prev" onClick={prev}>❮</a> : ''}

      </div>
    );


  }

  var first = [black, black_top]
  var second = [grey, grey_top]
  var third = [silver, silver_top]
  var fourth = [starlight, starlight_top]
  var color_img = [black, grey, silver, starlight];

  return (
    <div>
      <div className='macbook_plus_main'>
        <div className='macbook_name'>
        <p className="prodano_mobile">*** Продано 7 шт. ***</p>
          <p className='large_desc'>Ноутбук MacBook Air 13" M2 10-core GPU</p>
          <p className='medium_desc'>Чип Apple M2, 8-ядерный процессор, </p>
          <p className="medium_desc">10-ядерный графический процессор,</p>
          <p className='medium_desc'>16-ядерный Neural Engine </p>
        </div>
        <div className='macbook_cost'>
          <p className='macbook_title_cost'>,-{total} руб.</p>
        </div>
        <div className='macbook_photo '>

          {option == 0 ? <ShowBlack /> : ''}
          {option == 1 ? <ShowGrey /> : ''}
          {option == 2 ? <ShowSilver /> : ''}
          {option == 3 ? <ShowStarlight /> : ''}


        </div>
        <div className='macbook_colors'>
          <p className='cvet'>Цвет:</p>
          <button onClick={setBlack} className={option == 0 ? 'colors_button_active_black' : 'colors_button_black'}></button>
          <button onClick={setGrey} className={option == 1 ? 'colors_button_active_grey' : 'colors_button_grey'}></button>
          <button onClick={setSilver} className={option == 2 ? 'colors_button_active_silver' : 'colors_button_silver'}></button>
          <button onClick={setStarlight} className={option == 3 ? 'colors_button_active_starlight' : 'colors_button_starlight'}></button>
        </div>
        <div className='macbook_info'>
          <p className='small_desc'>Диаметр экрана: 13,6"</p>
          <p className='small_desc'>8ГБ RAM</p>
          <p className='small_desc'>SSD-накопитель: 256ГБ, 512ГБ, 1TБ, 2TБ</p>
          <p className='small_desc'>RAM: 8 ГБ, 16ГБ или 24ГБ</p>
          <p className="small_desc">Двойной адаптер питания USB-C 35 или 70W</p>
          <p className="small_desc">Подсветка клавиатуры</p>
        </div>
        <div className='power'>
          <p className='power_text'>Адаптер питания:</p>
          <button onClick={setMedium} className={power == "35W" ? 'power_selector_active' : 'power_selector'}>35W</button>
          <button onClick={setLarge} className={power == "70W" ? 'power_selector_active' : 'power_selector'}>70W</button>
        </div>
        <div className='ssd'>
          <p className='ssd_text'>SSD:</p>
          <button onClick={setSmallSsd} className={ssd == "256 GB" ? 'power_selector_active' : 'power_selector'}>256ГБ</button>
          <button onClick={setMediumSSd} className={ssd == "512 GB" ? 'power_selector_active' : 'power_selector'}>512ГБ</button>
          <button onClick={setLargeSSD} className={ssd == "1 TB" ? 'power_selector_active' : 'power_selector'}>1ТБ</button>
          <button onClick={setXLargeSsd} className={ssd == "2 TB" ? 'power_selector_active' : 'power_selector'}>2ТБ</button>
        </div>
        <div className='ram'>
          <p className='ram_text'>RAM:</p>
          <button onClick={setSmallRam} className={ram == "8 GB" ? 'power_selector_active' : 'power_selector'}>8ГБ</button>
          <button onClick={setMediumRam} className={ram == "16 GB" ? 'power_selector_active' : 'power_selector'}>16ГБ</button>
          <button onClick={setLargeRam} className={ram == "24 GB" ? 'power_selector_active' : 'power_selector'}>24ГБ</button>
        </div>
        <div className='macbook_kupyt'>
          <button onClick={toKart} className='knopka_kupyt'>Взять за {total} руб.</button>
          <p className='prodano'>*** Продано 7 шт. ***</p>
          <Modal />
        </div>

      </div>
      <button onClick={toKart} className='kupyt_mobile'>Взять за {total} руб.</button>
      <Modal />
    </div>
  );
}

export default AirM2Plus;