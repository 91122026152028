import React from "react";
import black from "../../pics/pro_max/black.jpg";
import black_back from "../../pics/pro_max/black_back.jpg";
import black_bottom from "../../pics/pro_max/black_bottom.jpg";
import black_top from "../../pics/pro_max/black_top.jpg";
import desert from "../../pics/pro_max/desert.jpg";
import desert_back from "../../pics/pro_max/desert_back.jpg";
import desert_bottom from "../../pics/pro_max/desert_bottom.jpg";
import desert_top from "../../pics/pro_max/desert_top.jpg";
import natural from "../../pics/pro_max/natural.jpg";
import natural_back from "../../pics/pro_max/natural.jpg";
import natural_bottom from "../../pics/pro_max/natural.jpg";
import natural_top from "../../pics/pro_max/natural.jpg";
import white from "../../pics/pro_max/white.jpg";
import white_back from "../../pics/pro_max/white_back.jpg";
import white_bottom from "../../pics/pro_max/white_bottom.jpg";
import white_top from "../../pics/pro_max/white_top.jpg";
import "../../App.css";
import { useState, useEffect } from "react";
import { replace } from "react-router-dom";
import axios from "axios";

function Promax() {

  //product codes
  const proMax = {
    small:  "RdbA7ObKAJGime7tXPrAseOha3tF",
    medium:  "VTAHiAjiTuXIVvVFnur4swtLtJgv",
    large:  "86bg1LHpSkUsoYxYULM6o6rkvilJ"
  };
  //---------------------------------------------------------------------------------------------------------------------------------------------------
  const [option, setOption] = useState(0); //colors option setting. Non modal view
  const [cena, setCena] = useState(130000); //original price setting. Both modal and non modal view
  const [storage, setStorage] = useState("256 GB"); //storage option string. Both modal and non modal view
  const [action, setAction] = useState(0); //reference to different photographic views of a device. Non modal view
  const [diz1ount, setDixchount] = useState(0); //discounted price option. Both modal and non modal view
  const [colorName, setColorName] = useState("Черный Титан"); //string name of device color. Modal viewv
  const [modalVisible, setModalVisible] = useState(0); //setting to view or close the modal
  const [productCode, setProductCode] = useState(proMax.small) //product code setting used to include in an email

  //var address = "bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0"
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const closeModal = () => {
    setModalVisible(0);
  };

  const toKart = () => {
    setModalVisible(1);
  };
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <a className="email_link" href={`mailto:${email}${params}`}>
        {children}
      </a>
    );
  };
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const makeSubject = "Покупаю телефон iPhone 16 Pro Max " + storage;
  const makeBody =
  "Телефон iPhone 16 Pro Max " + storage + "\n\n Цвет: " + colorName +
    "\n\nКод продукта:----- " +
    productCode + option + 
    "------\n\n\n Код скидки :\n\n\n Скриншот, как подтверждение об оплате (добавте скриншот),\n или ваш биткойн адрес: \n\n Имя:   \n\n Фамилия: \n\n Полный адрес для доставки: \n\n\n";
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const Modal = () => {
    return (
      <>
        <div className={modalVisible == 0 ? "darkBG_hidden" : "darkBG_visible"}>
          <div className="centered">
            <div className="modal">
              <div className="modalHeader">
                <p className="heading">Карзина</p>
              </div>
              <button onClick={closeModal} className="zakryt">
                Закрыть
              </button>
              <div className="modalContent">
                {/* content load here */}
                <div className="product">
                  <p className="karzyna_medium_desc">
                    Телефон iPhone 16 Pro Max {storage}
                  </p>
                  <br />
                  <p className="karzyna_medium_desc">{colorName}</p>

                  <img
                    className="karzyna_pic"
                    src={color_img[option]}
                    alt="телефон"
                  />

                  <p className="phone_no_discount">{cena} руб.</p>
                  <p className="medium_desc">
                  С кодом скидки {prxs[diz1ount]} руб.
                  </p>
                  <p className="medium_desc">Доставка 1000 руб.</p>
                  <p className="vsevo">Всево {prxs[diz1ount] + 1000} руб.</p>
                </div>
                <div className="info">
                  <div className="vnimanye">
                    <p>Внимание!</p>
                    <p>Оплата карточкой неработает!</p>
                  </div>
                  <p className="medium_dec">
                    Для безопасности вашей информации
                  </p>
                  <p>оплата за товар применяется криптовалютой</p>
                  <p className="vsevo">Информация об оплате!</p>
                  <p className="medium_desc">Адрес биткойн:</p>
                  <p className="p_address">
                    bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0
                  </p>
                  <div style={{ borderBottom: "2px solid black" }}></div>
                  <p className="medium_desc">
                    Канал отправления биткойн: BTC (Bitcoin){" "}
                  </p>
                  <br />
                  <p className="kupyt_info">
                    Необходимо сделать скриншот с вами использовонной платформы
                    криптовалюты как подтверждение оплаты,
                  </p>
                  <p className="kupyt_info">
                     или во второй способ, отправте ваш
                    биткойн адрес
                  </p>
                  <p className="kupyt_info">
                    Нажмите по ссылке внизу, чтобы отправить e-mail
                  </p>
                  <p className="kupyt_info">
                    в котором введёте вашу информацию для доставки товара
                  </p>
                  <p className="kupyt_info">
                    Вся информация о покупке по этой ссылке зашифрована
                  </p>
                  <br />
                  <Mailto
                    email={"info@maonline.shop"}
                    subject={makeSubject}
                    body={makeBody}
                  >
                    Отправить e-mail!
                  </Mailto>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const setBlack = () => {
    setAction(0);
    setOption(0);
    setColorName("Черный Титан");
  };

  const setDesert = () => {
    setAction(0);
    setOption(1);
    setColorName("Песочный Титан");
  };

  const setNatural = () => {
    setAction(0);
    setOption(2);
    setColorName("Натуральный Титан");
  };

  const setWhite = () => {
    setAction(0);
    setOption(3);
    setColorName("Белый Титан");
  };
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const setSmall = () => {
    setDixchount(0);
    setCena(130000);
    setStorage("256 gb");
    setProductCode(proMax.small)
  };

  const setMedium = () => {
    setDixchount(1);
    setCena(145000);
    setStorage("512 gb");
    setProductCode(proMax.medium)
  };

  const setLarge = () => {
    setDixchount(2);
    setCena(170000);
    setStorage("1 TB");
    setProductCode(proMax.large)
  };
//---------------------------------------------------------------------------------------------------------------------------------------------------
  const prev = () => {
    setAction(action - 1);
  };
  const next = () => {
    setAction(action + 1);
  };

  const ShowBlack = () => {
    return (
      <div className="slideshow_container">
        <img src={first[action]} alt="pmblack" className="pic_res" />
        {action == 0 ? (
          <a className="next" onClick={next}>
            ❯
          </a>
        ) : (
          ""
        )}
        {action == 1 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 2 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 3 ? (
          <a className="prev" onClick={prev}>
            ❮
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };
  const ShowDesert = () => {
    return (
      <div className="slideshow_container">
        <img src={second[action]} alt="pmblack" className="pic_res" />
        {action == 0 ? (
          <a className="next" onClick={next}>
            ❯
          </a>
        ) : (
          ""
        )}
        {action == 1 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 2 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 3 ? (
          <a className="prev" onClick={prev}>
            ❮
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };
  const ShowNatural = () => {
    return (
      <div className="slideshow_container">
        <img src={third[action]} alt="pmblack" className="pic_res" />
        {action == 0 ? (
          <a className="next" onClick={next}>
            ❯
          </a>
        ) : (
          ""
        )}
        {action == 1 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 2 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 3 ? (
          <a className="prev" onClick={prev}>
            ❮
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };
  const ShowWhite = () => {
    return (
      <div className="slideshow_container">
        <img src={fourth[action]} alt="pmblack" className="pic_res" />
        {action == 0 ? (
          <a className="next" onClick={next}>
            ❯
          </a>
        ) : (
          ""
        )}
        {action == 1 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 2 ? (
          <span>
            <a className="prev" onClick={prev}>
              ❮
            </a>{" "}
            <a className="next" onClick={next}>
              ❯
            </a>
          </span>
        ) : (
          ""
        )}
        {action == 3 ? (
          <a className="prev" onClick={prev}>
            ❮
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };
//---------------------------------------------------------------------------------------------------------------------------------------------------
  var first = [black, black_back, black_bottom, black_top];
  var second = [desert, desert_back, desert_bottom, desert_top];
  var third = [natural, natural_back, natural_bottom, natural_top];
  var fourth = [white, white_back, white_bottom, white_top];
  var prxs = [98000, 110000, 130000];
  var color_img = [black, desert, natural, white];
//---------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div>
      <div className="main">
        <div className="phone_name">
          <p className="prodano_mobile">*** Продано 73 шт. ***</p>
          <p className="large_desc">iPhone 16 Pro Max</p>
        </div>
        <div className="phone_cost">
          <p className="medium_desc">,-{cena} руб. </p>
          <p className="medium_desc">со скидкой ,-{prxs[diz1ount]} руб.</p>
        </div>
        <div className="photo">
          {option == 0 ? <ShowBlack /> : ""}
          {option == 1 ? <ShowDesert /> : ""}
          {option == 2 ? <ShowNatural /> : ""}
          {option == 3 ? <ShowWhite /> : ""}
        </div>
        <div className="colors">
          <p className="cvet">Цвет:</p>
          <button
            onClick={setBlack}
            className={
              option == 0 ? "colors_button_active_black" : "colors_button_black"
            }
          ></button>
          <button
            onClick={setDesert}
            className={
              option == 1
                ? "colors_button_active_desert"
                : "colors_button_desert"
            }
          ></button>
          <button
            onClick={setNatural}
            className={
              option == 2
                ? "colors_button_active_titanium"
                : "colors_button_titanium"
            }
          ></button>
          <button
            onClick={setWhite}
            className={
              option == 3 ? "colors_button_active_white" : "colors_button_white"
            }
          ></button>
        </div>
        <div className="phone_info">
        <p className="medium_desc">Выбор цвета:</p>
        <p className="small_desc">Черный титан</p>
        <p className="small_desc">Песочный титан</p>
        <p className="small_desc">Натуральный титан</p>
        <p className="small_desc">Белый титан</p>
        <br/>
          <p className="small_desc">Размеры: 163 - 78 - 8 мм</p>
          <p className="small_desc">Вес: 227 грам</p>
          <p className="small_desc">Диаметр экрана: 6.9 дюймов</p><br/>
        </div>
        <div className="storage">
          <p className="xranilische">Память:</p>
          <button
            onClick={setSmall}
            className={
              cena == 130000 ? "storage_selector_active" : "storage_selector"
            }
          >
            256 гб
          </button>
          <button
            onClick={setMedium}
            className={
              cena == 145000 ? "storage_selector_active" : "storage_selector"
            }
          >
            512 гб
          </button>
          <button
            onClick={setLarge}
            className={
              cena == 170000 ? "storage_selector_active" : "storage_selector"
            }
          >
            1 тб
          </button>
        </div>
        <div className="kupyt">
          <button onClick={toKart} className="knopka_kupyt">
            Взять за {prxs[diz1ount]} руб.
          </button>
          <p className="prodano">*** Продано 73 шт. ***</p>

          <Modal />
        </div>
      </div>
      <button onClick={toKart} className="kupyt_mobile">
      Взять за {prxs[diz1ount]} руб.
      </button>
      <Modal />
    </div>
  );
}
export default Promax;
