import React, { useEffect, useState } from 'react';
import {data} from './upload/data'
import './otzyvy.css'
import one from './upload/koment/iphone1.webp'
import two from './upload/koment/two.webp'
import three from './upload/koment/three.webp'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from 'react-router-dom';

const Otzyvy2 = () => {

    console.log("Count: " + data[0].user_name);
    const [page, setPage] = useState(1);
    const [visible, setVisible] = useState(0)
    const [value, setValue] = useState("");

    const submitChange = () =>{

        setVisible(1)
        setValue("")

    }

    const handleChange = (e) =>{
        setValue(e.target.value)
    }

    const handlePageChange = (pageNumber) => {
        if (
          pageNumber > 0 &&
          pageNumber <= data.length / 5 &&
          pageNumber !== page
        )
          setPage(pageNumber);    
      };
    
    return (
        <div className='sekcya_komentary'>
            
            
            <div className='pagination_header'><span className='text_komentary'>
                Коментары</span></div>
                <div className='write_comment_block'>
                    <input className='text_input' type='text' onChange={handleChange} value={value} placeholder='Введите код для коментара'/>
                    <p className={visible === 0 ? "error_text_disabled" : "error_text"}>Код неправилный!</p>
                    <button onClick={submitChange} className='button_check_code'>Отправить</button>
                </div>
            {
                data.length && (
                    <div className='pagination_comments'>
 {
                data.slice(page * 10 - 10, page * 10).map((data,key)=>{
                    return(
                        <div className='pagination_komentary' key={key}>
                            <div className='pagination_header_inside'>
                            <div className='pagination_user_name'>{data.user_name}</div>
               <div className='pagination_date'>{data.date}</div>
               <div className='pagination_time'>{data.time}</div>
                            </div>
                            
                            <div className='content'>
                                {data.photo != "one" ? "" : <img className='koment_photo' style={{width: 90, height: 70}} src={one} alt='one'/>}
                                {data.photo != "two" ? "" : <img className='koment_photo' style={{width: 70, height: 70}} src={two} alt='two'/>}
                                {data.photo != "three" ? "" : <img className='koment_photo' style={{width: 70, height: 70}} src={three} alt='three'/>}
                                
            <div className='pagination_commentar'>{data.comment}</div>            
            </div>
                        </div>
                    )
                })
            }
                    </div>
                )
            }
            {
                data.length > 0 && (
                    <div className='pagination_pages'>
                        <button onClick={()=> handlePageChange(page - 1)}
                            className={page === 1 ? "disabled" : "enabled"} >назад</button>
                        {[...Array(Math.floor(data.length / 10)+1)].map((_, i)=>{
                            return(
                                <button key={i + 1} onClick={()=> handlePageChange(i +1)}
                                className={page === i + 1 ? "current_page" : "other_page"}>
                                    {i + 1}
                                </button>
                            )
                        })}
                        <button onClick={()=> handlePageChange(page + 1)}
                            className={page === data.length / 10 || page === Math.floor(data.length / 10) +1 ? "disabled" : "enabled"}>
                            далее
                        </button>
                    </div>
                )
            }
            <div className='footer'>
    <div className='text_block'>
    <p className='footer_text'>
        Бсе правы защищены
    </p>
    <p className='footer_text'>
        Гарантия на каждый товар: 2 года
    </p>
    <p className='footer_text'>
        Гарантия применяется в случае кражи, потери, или других обстоновок, которые не являются результатом неправильного пользования продукта
    </p>
    <p className='footer_text'>
        Доставка товара от 2 до 5 недель. В случае неправильного адреса - до 7 недель
        <p className='footer_text'>Все продукты на этом сайте являются новыми, в оригинaльной упаковке</p>
    </p>
    </div>
</div>
           
        </div>
    );
}

export default Otzyvy2;
