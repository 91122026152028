import './App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import Iphone16 from './iphone_page';
import Macbook from './macbook_page';
import Glavnaya from './glavnaya';
import Otzyvy from './otzyvy'
import kart from './pics/shopping-cart-thin.svg'
import log from './pics/log.svg'
import menu from './pics/menu.svg'
import black from './pics/pro_max/black.jpg';
import black_back from './pics/pro_max/black_back.jpg';
import black_bottom from './pics/pro_max/black_bottom.jpg';
import black_top from './pics/pro_max/black_top.jpg';
import desert from './pics/pro_max/desert.jpg';
import desert_back from './pics/pro_max/desert_back.jpg';
import desert_bottom from './pics/pro_max/desert_bottom.jpg';
import desert_top from './pics/pro_max/desert_top.jpg';
import natural from './pics/pro_max/natural.jpg';
import natural_back from './pics/pro_max/natural.jpg';
import natural_bottom from './pics/pro_max/natural.jpg';
import natural_top from './pics/pro_max/natural.jpg';
import white from './pics/pro_max/white.jpg';
import white_back from './pics/pro_max/white_back.jpg';
import white_bottom from './pics/pro_max/white_bottom.jpg';
import white_top from './pics/pro_max/white_top.jpg';
import karzyna from './karzyna'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Link
} from 'react-router-dom';
import Karzyna from './karzyna';
import Otzyvy2 from './otzyvy2';

function App() {
  const [cartVisible, setCartVisible] = useState(false)
  //begining of cart code---------------------------------------------
  const [item, setItem] = useState([])  /* old data container from local storage */
  const [libData, setLibData] = useState([])   /* new data that was compared to old data  */
  const product_lib = [                        /*  product info library*/
    {
      product_id: "86bg1LHpSkUsoYxYULM6o6rkvilJ",
      product_colors: [black, desert, natural, white],
      product_name: 'Телефон iPhone 16 Pro Max 256 GB',
      product_price: '98000 руб.',
      BTC_address: 'bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0'
    },
    {
      product_id: "VTAHiAjiTuXIVvVFnur4swtLtJgv",
      product_colors: [black, desert, natural, white],
      product_name: 'Телефон iPhone 16 Pro Max 512 GB',
      product_price: '110000 руб.',
      BTC_address: 'bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0'
    },
    {
      product_id: "86bg1LHpSkUsoYxYULM6o6rkvilJ",
      product_colors: [black, desert, natural, white],
      product_name: 'Телефон iPhone 16 Pro Max 1 TB',
      product_price: '130000 руб.',
      BTC_address: 'bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0'
    }
  ]

  /* begining of fuction retrieve data from local storage and pushing that data into array */

  const updating = () => {
    var arr = []
    for (var i = 1; i < localStorage.length + 1; i++) {
      var storage_item = JSON.parse(localStorage.getItem("produkt " + i))
      arr.push(storage_item)
    }

    return arr
  }

  /* end of function */

  /* begining of funtion for sorting out data based on retrieved product id, 
  comparison is happening between product library and retrieved data from local storage */

  const createLibData = () => {

    var arr = []


    product_lib.map((data) => {

      item.map((item_data) => {

        if (data.product_id === item_data.phone_id) {


          var obj = {
            product_id: data.product_id,
            product_colors: data.product_colors[item_data.phone_color],
            product_name: data.product_name,
            product_price: data.product_price,
            BTC_address: 'bc1qw0ul2xcc3s3gkvxfmw8z8dw3k3dzq37alf2ja0'
          }
          arr.push(obj)
        } else {

        }

      })
    })

    return arr

  }
  /* end of sorting function */

  //----variables to store old and sorted data arrays----------------
  var oldData = updating()
  var replacedData = createLibData()


  const [cart, setCart] = useState(0)
  

  const upd = (number) => {
    setCart(number)

  }
  useEffect(() => {
    setCart(localStorage.length)
  })
  const clearStorage = () => {
    localStorage.clear()
    setCart(0)
    setItem([])
    setLibData([])
    setCartVisible(!cartVisible)
  }

  

  var linkToMain = "https://maonline.shop"
  var linkToTest = "http://192.168.0.40:3000"
  var home_page = "http://localhost:3000"
  var iphone_page = "/iphone16"
  var macbook_page = "/macbook"
  var comments_page = "/otzyvy"
  const [submenu, setSubMenu] = useState(0)

  const showMenu = () => {
    setSubMenu(1)
  }
  const hideSubMenu = () => {
    setSubMenu(0)
  }

  const ShowCart = () => {
    setCartVisible(!cartVisible)
    setItem(oldData)
    setLibData(replacedData)
  }
  
  

  const HiddenMenu = () => {
    return (
      <div>
        <div className='hidden_menu'>
          <button className='iphn'><Link to='/iphone16'>Телефоны Iphone 16</Link></button>
          <button className='mbook'><Link to='/macbook'>Ноутбуки MacBook</Link></button>
          <button className='ipd'>Планшеты iPad</button>
          <button className='airpd'>Наушники AirPods</button>
          <button className='wtch'>Часы Apple Watch</button>
         {/*  <button className='drg'>Другие товары</button> */}
        </div>
      </div>
    );
  }
  const MobileHiddenMenu = () => {
    return (

      <div className='mobile_hidden_menu'>
        <button className='mobile_iphn'><Link to='/iphone16'>Телефоны Iphone 16</Link></button>
        <button className='mobile_mbook'><Link to='/macbook'>Ноутбуки MacBook</Link></button>
        <button className='mobile_ipd'>Планшеты iPad</button>
        <button className='mobile_airpd'>Наушники AirPods</button>
        <button className='mobile_wtch'>Часы Apple Watch</button>
        {/* <button className='mobile_drg'>Другие товары</button> */}
        <button className='mobile_otzyvy'><Link to='/otzyvy'>Чат</Link></button>
        <button className='mobile_kontakty'>Контакт: info@maonline.shop</button>
      </div>

    );
  }
  
  const toKart = () => {
    setCart(cart + 1)
  }
  return (

    /*main page body begins */
    <div className='App'>

      <div className='title2'>


        <div className='page_logo_section'>
          <div className='logo'><a href={linkToMain}>Мaonline</a></div>
          <div className='magazin'>Магазин
            <HiddenMenu />
          </div>
          <div className='otzyvy'>
            
          <Link to='/otzyvy'>Чат</Link></div>
          <div className='kontakty'>Контакт: info@maonline.shop</div>
        </div>
        <div className='reg_kart_section'>


          
        </div>
      </div>

      <div className='mobile_title2'>
        <div className='mobile_menu'><img onClick={submenu === 0 ? showMenu : hideSubMenu} src={menu} alt='menu' /></div>
        {submenu === 1 ? <MobileHiddenMenu /> : ''}
        <div className='mobile_page_logo_section'>
          <div className='mobile_logo'><a href={linkToMain}>Мaonline</a></div>
        </div>
        
      </div>


      <Routes>
        <Route index element={<Glavnaya/>} />
        <Route path='iphone16' element={<Iphone16/>} />
        <Route path='macbook' element={<Macbook/>} />
        <Route path='otzyvy' element={<Otzyvy2/>} />
       
      </Routes>

    </div>

    //main page body ends
  );
}

export default App;
