import React, { useEffect, useState } from 'react';
import {data} from './upload/data'
import './otzyvy.css'

import one from './upload/koment/iphone1.webp'
import two from './upload/koment/two.webp'
import three from './upload/koment/three.webp'

const Otzyvy = () => {

    const [itemNumber, setItemNumber] = useState(0)
    
    useEffect(()=>{
        const interval = setInterval(()=>{
            setItemNumber((itemNumber)=>{
                if(itemNumber < 11){
                    return itemNumber + 1
                }else{
                    return 0
                }
                
            })
        }, 15000)
        return ()=> clearInterval(interval)
    },[])

    
    

    return (
        <div className='pagination_komentary'>
            <div className='pagination_header_inside'>
               <div className='pagination_user_name'>{data[itemNumber].user_name}</div>
               <div className='pagination_date'>{data[itemNumber].date}</div>
               <div className='pagination_time'>{data[itemNumber].time}</div>
            </div>
            <div className='content'>
            {data[itemNumber].photo != "one" ? "" : <img className='koment_photo' style={{width: 90, height: 70}} src={one} alt='one'/>}
            {data[itemNumber].photo != "two" ? "" : <img className='koment_photo' style={{width: 70, height: 70}} src={two} alt='two'/>}
            {data[itemNumber].photo != "three" ? "" : <img className='koment_photo' style={{width: 70, height: 70}} src={three} alt='three'/>}
            <div className='pagination_commentar'>{data[itemNumber].comment}</div>            
            </div>
            
        </div>
    );
}

export default Otzyvy;
