import React from 'react';
import { useState } from 'react';
import First from './components/macbook/airM2'
import Second from './components/macbook/airM2plus'
import Third from './components/macbook/airM3_15'
import Fourth from './components/macbook/airM3'
import Fifth from './components/macbook/airM3plus'
import Sixth from './components/macbook/proM3_14'
import './macbook.css'

const Macbook = () =>{

    const [mac, setMac] = useState(0)

    const setFirst = () => {
        setMac(0)
    }
    const setSecond = () => {
        setMac(1)
    }
    const setThird = () => {
        setMac(2)
    }
    const setFourth = () => {
        setMac(3)
    }
    const setFifth = () => {
        setMac(4)
    }
    const setSixth = () => {
        setMac(5)
    }
    
    

    var optn = ['MacBook Air M2 13"', 'MacBook Air M2 13"', 'MacBook Air M3 15"', 'MacBook Air M3 13"', 
    'MacBook Air M3 13"', 'MacBook Pro 14" М3']
    var card = [<First />, <Second />, <Third />, <Fourth />,<Fifth />, <Sixth />]

    const PageZero = () => {
        return (
            <span className='macbook_page_left'>
                <button onClick={setSecond} className='macbook_right_active' >{optn[1] + " ❯"}</button>
            </span>
        );
    }

    const PageOne = () => {
        return (
            <span className='macbook_page_left'>
                <button onClick={setFirst} className='macbook_right_active' >{"❮ " + optn[0]}</button>
                <button onClick={setThird} className='macbook_right_active' >{optn[2] + " ❯"}</button>
            </span>
        );
    }

    const PageTwo = () => {
        return (
            <span className='macbook_page_left'>
                <button onClick={setSecond} className='macbook_right_active' >{"❮ " + optn[1]}</button>
                <button onClick={setFourth} className='macbook_right_active' >{optn[3] + " ❯"}</button>
            </span>
        );
    }
    const PageThree = () => {
        return (
            <span className='macbook_page_left'>
                <button onClick={setThird} className='macbook_right_active' >{"❮ " + optn[2]}</button>
                <button onClick={setFifth} className='macbook_right_active' >{optn[4] + " ❯"}</button>
            </span>
        );
    }

    const PageFour = () => {
        return (
            <span className='macbook_page_left'>
                <button onClick={setFourth} className='macbook_right_active' >{"❮ " + optn[3]}</button>
                <button onClick={setSixth} className='macbook_right_active' >{optn[5] + " ❯"}</button>
            </span>
        );
    }

    const PageFive = () => {
        return (
            <span className='macbook_page_left'>
                <button onClick={setFifth} className='macbook_right_active' >{"❮ " + optn[4]}</button>
            </span>
        );
    }

    

    return(
        <div>
            

            {/* navigation */}
            <div className='macbook_parent'>
                <div className='macbook_navi'>
                    {mac == 0 ? <PageZero /> : ""}
                    {mac == 1 ? <PageOne /> : ""}
                    {mac == 2 ? <PageTwo /> : ""}
                    {mac == 3 ? <PageThree /> : ""}
                    {mac == 4 ? <PageFour /> : ""}
                    {mac == 5 ? <PageFive /> : ""}                    
                </div>

                {/*content body*/}
                {card[mac]}

            </div>
        </div>
    );
}

export default Macbook;